import React, { useState, useEffect ,useMemo} from "react";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useHistory } from "react-router";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

function MapView(props) {
  const history = useHistory();
  const [markers, setMarkers] = useState([]);
  const [mapsDayta, setMapsDayta] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeMarkerIds, setActiveMarkerIds] = useState([]);

  const mapViewConfiguration = useMemo(() => {
    return props.headerTab?.mapview_configuration ? JSON.parse(props.headerTab.mapview_configuration) : null;
  }, [props.headerTab]);

  useEffect(() => {
    // Update mapsDayta whenever props.sendDataToCard changes
    setMapsDayta(props.sendDataToCard || []);
  }, [props.sendDataToCard]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (mapViewConfiguration && mapsDayta.length) {
          const { longitude_id, latitude_id } = mapViewConfiguration;

          const markerData = mapsDayta
            ?.map(record => {
              const lng = record[longitude_id];
              const lat = record[latitude_id];
              if (lng && lat) {
                return {
                  id: record.recordId,
                  name: record.recordTitlesData[0]?.titleName || "Unknown",
                  info: record.recordRow1Data.map(row => row.recordRow1Name),
                  position: {
                    lat: parseFloat(lat),
                    lng: parseFloat(lng),
                  },
                };
              }
              return null;
            })
            .filter(marker => marker !== null);

          setMarkers(markerData || []);
          setActiveMarkerIds(markerData?.map(marker => marker.id) || []);
        }
      } catch (error) {
        console.error("Error fetching marker data:", error.message || error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [mapsDayta, mapViewConfiguration]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_APIKEY,
  });

  const handleMarkerClick = (markerId) => {
    setActiveMarkerIds((prev) =>
      prev.includes(markerId) ? prev.filter(id => id !== markerId) : [...prev, markerId]
    );
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
  };

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined className="spinLoader" style={{ fontSize: "52px" }} spin />}>
      <GoogleMap
        onLoad={handleOnLoad}
        mapContainerStyle={{ width: "83vw", height: "75vh" }}
        options={mapOptions}
      >
        {markers.map(({ id, name, position, info }) => (
          <Marker key={id} position={position} onClick={() => handleMarkerClick(id)}>
            {activeMarkerIds.includes(id) && (
              <InfoWindow
                position={position}
                onCloseClick={() => setActiveMarkerIds((prev) => prev.filter((markerId) => markerId !== id))}
              >
                <div onClick={() => history.push(`/window/${props.headerTab.ad_window_id}/${id}`)}>
                  <h3>{name}</h3>
                  <ul>
                    {info.map((infoItem, index) => (
                      <li key={index}>{infoItem}</li>
                    ))}
                  </ul>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </Spin>
  );
}

export default MapView;
