import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card, Select, message, Row, Col, Tooltip, Button } from "antd";
import { LeftOutlined, RightOutlined, MinusCircleOutlined } from '@ant-design/icons';
import ThemeJson from "../../constants/UIServer.json"
import { useGlobalContext } from "../../lib/storage";
import iconSide from "../../assets/images/hoverLine.svg"
import backLeft from "../../assets/images/menuHide.svg"
import "../../styles/app.css";
import "../../styles/antd.css";
import "./menu.css";

const { Sider } = Layout;

const SideMenu = (props) => {
  // const textInput = React.useRef(null);
  const selectedApp = localStorage.getItem("appName");
  const { globalStore, setGlobalStore } = useGlobalContext();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const Themes = ThemeJson;
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const [view, setView] = useState(false)
  const { fullMenuToggle, setFullMenuToggle, setMenuToggle, menuToggle, setShowToggler, setIframeUrl } = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();
  let lowerSubMenu = JSON.parse(localStorage.getItem("lowerSubMenu"));

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
            item.children.map((subItem, subIndex) => {
              if (subItem.children && subItem.children.length > 0) {
                subItem.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
              }
              // item.children[subIndex] = subItem;
              return null;
            });
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }

  }, [menuList]);



  const selectMenuToNavigate = (data) => {

    localStorage.setItem("lowerSubMenuItem", data.key);
    if (data.type === "Report") {
      localStorage.setItem("windowType", "Report")
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      localStorage.setItem("windowType", "Dashboard")
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Process Window") {
      history.push(`/processWindow/window/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else if (data.type === "External") {
      if (data.navigation === "NewTab") {
        window.open(`${data.url}`)
      } else if (data.navigation === "EmbeddedView") {
        setIframeUrl(data.url)
      } else if (data.navigation === "popopWindow") {
        const popupUrl = data.url;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const popupWidth = 600; // Set the width of the popup window
        const popupHeight = 400; // Set the height of the popup window
        const leftPosition = (screenWidth - popupWidth) / 2;
        const topPosition = (screenHeight - popupHeight) / 2;
        // Define other options for the popup window
        const popupOptions = `
        width=${popupWidth},
        height=${popupHeight},
        top=${topPosition},
        left=${leftPosition},
        resizable=yes,
        scrollbars=yes,
        status=no,
        toolbar=no,
        menubar=no,
        location=no `;
        window.open(popupUrl, "popupWindow", popupOptions);
      }
    } else {
      message.warning("Not Available");
    }
  };


  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };



  const fullToggle = () => {
    setFullMenuToggle((t) => !t);
    setTimeout(() => {
      setMenuToggle((t) => !t);
      setShowToggler(false);
    }, 0);
  }

  let lowerSubMenuItem = localStorage.getItem("lowerSubMenuItem")
  let sideMenuStyle = ""
  let rightMargin = ""
  if (menuToggle) {
    sideMenuStyle = "0px"
    rightMargin = "10px"
  } else {
    sideMenuStyle = "0px"
    rightMargin = "0.7em"
  }
  const innerWidth = windowSize.innerWidth
  return (
    <div className="responsiveSideMenu" style={{ marginTop: '0.7em', marginLeft: sideMenuStyle, marginRight: rightMargin, fontFamily: 'Inter' }}>
      {lowerSubMenu !== undefined && lowerSubMenu !== null && lowerSubMenu.length !== 0 ?
        <Sider collapsed={menuToggle} onCollapse={null} style={{ backgroundColor: '#FFFFFF' }} className={menuToggle ? (fullMenuToggle ? "fullmenu-inactive" : "fullmenu-active") : null}>

          {/* <Card style={Themes.sideMenu.sideMenuCard}> */}
          <Scrollbars
            style={{
              // marginLeft:'1.5px',
              height: window.innerWidth >= 1700 ? "86vh" : window.innerWidth >= 1600 ? "84vh" : "82vh",
              transition: 'height 0.3s'
            }}
            universal
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            thumbSize={90}
            renderView={renderView}
            renderThumbHorizontal={renderThumb}
            renderThumbVertical={renderThumb}
          >

            <Menu
              selectedKeys={lowerSubMenuItem}
              className="sideMenu"
              style={{
                backgroundColor: '#FFFFFF',
                paddingTop: '1vh',
                color: '#0C173A',
                fontSize: '0.8125rem',
                paddingRight: '1.2vw',
                paddingLeft: '0.7vw',
              }}
              mode="vertical"
            >
              {lowerSubMenu ? (
                lowerSubMenu.map((menuList) => {
                  const getHref = () => {
                    if (menuList.children && menuList.children.length > 0) {
                      const firstChild = menuList.children[0];
                      switch (firstChild.type) {
                        case 'Report':
                          return `/reports/report/${firstChild.id}`;
                        case 'Dashboard':
                          return `/analytics/dashboard/${firstChild.id}`;
                        case 'Generic':
                          return `/window/list/${firstChild.id}`;
                        case 'Custom':
                          return `/others/window/${firstChild.id}`;
                        case 'External':
                          return firstChild.url ? firstChild.url : '#';
                        default:
                          return '#';
                      }
                    }

                    // Default href if no children
                    switch (menuList.type) {
                      case 'Report':
                        return `/reports/report/${menuList.id}`;
                      case 'Dashboard':
                        return `/analytics/dashboard/${menuList.id}`;
                      case 'Generic':
                        return `/window/list/${menuList.id}`;
                      case 'Custom':
                        return `/others/window/${menuList.id}`;
                      case 'External':
                        return menuList.url ? menuList.url : '#';
                      default:
                        return '#';
                    }
                  };

                  const href = getHref();

                  // If menuList has children, render as Menu.ItemGroup
                  if (menuList.children && menuList.children.length > 0) {
                    return (
                      <Menu.ItemGroup
                        className="lineMenu"
                        key={menuList.key || menuList.id}
                        title={
                          <span
                            title={menuList.title}
                            style={{
                              fontSize: '13px',
                              color: '#0A0213',
                              fontWeight: 600,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {menuList.title}
                          </span>
                        }
                      >
                        {menuList.children.map((childMenuItem, childIndex) => (
                          <Menu.Item
                            className="lineMenu"
                            key={childMenuItem.key || childMenuItem.id}
                            style={{ width: 'auto', paddingLeft: '10px' }}
                            onClick={() =>
                              selectMenuToNavigate({
                                type: childMenuItem.type,
                                id: childMenuItem.id,
                                key: childMenuItem.key,
                                title: childMenuItem.title,
                                index: childIndex,
                              })
                            }
                          >
                            <span
                              style={{ fontSize: '13px', fontWeight: 200 }}
                              title={childMenuItem.title}
                            >
                              {childMenuItem.title}
                            </span>
                          </Menu.Item>
                        ))}
                      </Menu.ItemGroup>
                    );
                  }

                  // Default rendering for items without children
                  return (
                    <Menu.Item
                      id={
                        history.location.pathname === '/window/list/7008'
                          ? menuList.title === 'Quick Purchase Order'
                            ? 'step1'
                            : ''
                          : history.location.pathname === '/others/window/7359'
                          ? menuList.title === 'Quick Wastage Entry'
                            ? 'step11'
                            : ''
                          : history.location.pathname === '/others/window/7360'
                          ? menuList.title === 'Quick Goods Receipt'
                            ? 'step11'
                            : ''
                          : history.location.pathname === '/others/window/7296'
                          ? menuList.title === 'Quick Stock Receipt'
                            ? 'step13'
                            : ''
                          : ''
                      }
                      style={{
                        color: '#0C173A',
                        borderRadius: '5px',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.4)',
                        height: 'auto', // Dynamic height
                        maxHeight:"3em",
                        padding: '5px 10px', // Add padding for spacing
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      
                      key={menuList.title}
                      onClick={() =>
                        selectMenuToNavigate({
                          type: menuList.type,
                          id: menuList.id,
                          key: menuList.title,
                          navigation: menuList.navigation,
                          url: menuList.url,
                        })
                      }
                    >
                      <a
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                        href={href}
                        style={{ 
                          textDecoration: 'none',
                          color: 'inherit',
                          display: 'block', // Ensure the text behaves as a block element
                          overflow: 'hidden', // Hide overflow content
                          textOverflow: 'ellipsis', // Add ellipsis for overflow
                          whiteSpace: 'normal', // Allow wrapping
                          lineHeight: '1.5em', // Adjust the line height (example: 1.5 times the font size)
                          maxHeight: '3em', // Limit to 2 lines (2 lines x 1.5em line-height = 3em)
                        }}
                        target={
                          menuList.type === 'External' && menuList.navigation === 'NewTab'
                            ? '_blank'
                            : '_self'
                        }
                        rel={
                          menuList.type === 'External' && menuList.navigation === 'NewTab'
                            ? 'noopener noreferrer'
                            : undefined
                        }
                      >
                        {menuList.title}
                      </a>
                    </Menu.Item>
                  );
                })
              ) : (
                setMenuToggle(true) && setFullMenuToggle(true)
              )}
            </Menu>

          </Scrollbars>
          <div className={menuToggle ? "toggle" : "menu-toggle"}>
            <Tooltip title={menuToggle ? "Show Menu" : "Hide Menu"} placement="left">
              <Button style={{ border: "none", paddingRight: "1px", background: "transparent", boxShadow: "none" }} onClick={fullToggle} >
                {menuToggle ? (view ? <img style={{ transform: view ? 'scaleX(-1)' : 'scaleX(1)' }} onMouseLeave={() => setView(false)} src={backLeft} /> : <img onMouseEnter={() => setView(true)} src={iconSide} />) : view ? <img onMouseLeave={() => setView(false)} src={backLeft} /> : <img onMouseEnter={() => setView(true)} src={iconSide} />}
              </Button>
            </Tooltip>
          </div>
          {/* </Card> */}
        </Sider> : ""}

    </div>
  );
};

export default SideMenu;